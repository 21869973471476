export const MethodColors = {
  POST: "is-success",
  DELETE: "is-danger",
  PUT: "is-warning",
  PATCH: "is-warning",
  GET: "is-info",
};

export function replaceUrls(str) {
  /*
  const regex = /https?:\/\/\S+/g; // Regular expression to match URLs
  const replacement = "URL"; // Replacement string
  const new_text = String(text).replace(regex, replacement); // Replace all URLs with the replacement string
  return new_text;
  */
  const regex = /<[^>]*>/g; // regex to match any <...> tags
  const urlRegex = /https?:\/\/[^\s/$.?#].[^\s]*/g; // regex to match URLs
  return str.replace(regex, "[URL]").replace(urlRegex, "[URL]");
}

export function removeEmailHeaders(text) {
  const regexList = [
    /^[\s\S]*?From:.*\n/gm,
    /^[\s\S]*?Sent:.*\n/gm,
    /^[\s\S]*?To:.*\n/gm,
    /^[\s\S]*?Cc:.*\n/gm,
    /^[\s\S]*?Subject:.*\n/gm,
    /^[\s\S]*?Reply-To:.*\n/gm,
    /^[\s\S]*?Received:.*\n/gm,
    /^[\s\S]*?Message-ID:.*\n/gm,
    /^[\s\S]*?X-Mailer:.*\n/gm,
    /^[\s\S]*?MIME-Version:.*\n/gm,
    /^[\s\S]*?Content-Type:.*\n/gm,
    /^[\s\S]*?Content-Transfer-Encoding:.*\n/gm,
    /^[\s\S]*?X-.*:.*\n/gm,
    /^[\s\S]*?Precedence:.*\n/gm,
    /^[\s\S]*?User-Agent:.*\n/gm,
  ];

  //(e.g. '-------- FOWARDED EMAIL------')
  const excludeList = [/^-{2,}\s*[A-Za-z\s]*-{2,}\n/gm];

  let result = text;

  for (const regex of regexList.slice(0, 15)) {
    result = result.replace(regex, "");
  }

  for (const regex of excludeList) {
    result = result.replace(regex, "");
  }

  return result;
}

export function truncateTextWords(text, max) {
  const words = String(text).split(" "); // Split the text into words
  const truncatedWords = words.slice(0, max); // Truncate the first 500 words
  const truncatedText = truncatedWords.join(" "); // Join the truncated words back into a text
  return truncatedText;
}

export function formatPlainTextInHtml(plainText) {
  // GPT - MESS no work.
  const pattern = /((<\s*https?:\/\/[^>\s]+>)|(https?:\/\/[^<>\s]+))/gi;
  return plainText.replace(pattern, (match) => {
    const url = match.startsWith("<") ? match.slice(1, -1) : match;
    const safeUrl = encodeURI(url);
    return `<a href="${safeUrl}" rel="noopener noreferrer" target="_blank">${url}</a>`;
  });
}

export function removeExtraSpacesAndLines(text) {
  return text.replaceAll(/\s{2,}/g, " ").replaceAll(/\n{2,}/g, "\n");
}

export function elipsis(text, maxChars) {
  if (text.length <= maxChars) {
    return text;
  } else {
    // Split the text into words and remove any leading/trailing whitespace
    const words = text
      .substring(0, maxChars)
      .split(/\s+/)
      .map((word) => word.trim());

    // Check if the last word ends with any punctuation
    const lastWord = words[words.length - 1];
    if (/[\p{P}\p{S}]/u.test(lastWord.slice(-1))) {
      // If the last word ends with punctuation, just return the truncated text
      return words.join(" ") + "...";
    } else {
      // If the last word doesn't end with punctuation, remove it and add the elipsis
      return words.slice(0, -1).join(" ") + "...";
    }
  }
}

import prettyMilliseconds from "pretty-ms"; //hackyy.
/**/
export function prettyMs(then) {
  var ms = Date.now() - then;
  // return 4;
  if (ms < 1000 * 60) return "now";
  return prettyMilliseconds(ms, { compact: true });
}

import TimeAgo from "javascript-time-ago";
// English.
import en from "javascript-time-ago/locale/en";
TimeAgo.addDefaultLocale(en);
// Create formatter (English).
const myTimeAgo = new TimeAgo("en-US");
myTimeAgo.format(new Date());
// "just now"
export function timeAgo(dateStr) {
  if (!dateStr) return "";
  return myTimeAgo.format(new Date(dateStr));
  // const myDate = new Date('2023-02-12T08:30:00');
  //console.log(timeAgo(myDate));
  var date = new Date(dateStr);
  const formatter = new Intl.RelativeTimeFormat("en", { numeric: "auto" });
  var difference = date - Date.now() * -1;
  let unit;
  let magnitude;
  console.log("DIFF" + difference);
  if (difference < 1000) {
    unit = "second";
    magnitude = difference / 1000;
  } else if (difference < 1000 * 60) {
    unit = "minute";
    magnitude = difference / 1000 / 60;
  } else if (difference < 1000 * 60 * 60) {
    unit = "hour";
    magnitude = difference / 1000 / 60 / 60;
  } else if (difference < 1000 * 60 * 60 * 24) {
    unit = "day";
    magnitude = difference / 1000 / 60 / 60 / 24;
  } else {
    unit = "month";
    magnitude = difference / 1000 / 60 / 60 / 24 / 30;
  }

  return formatter.format(Math.floor(magnitude), unit);
}

// const { match } = require('node-match-path')
import { match as matchLib } from "node-match-path";
export function matchPath(path, route) {
  return matchLib(path, route);
  //match('/user/:userId', '/user/5')
}

/*
{
  matches: true,
  params: {
    userId: '5'
  }
}
*/
